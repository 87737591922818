import React from 'react';

import { string } from 'prop-types';
import { Box, Center, Image, Text } from '@chakra-ui/react';

import useLang from '@/hooks/useLang';
import useInvitation from '@/hooks/useInvitation';

import CountDown from '@/components/Common/CountDown';
import AddToCalendar from '@/components/Common/AddToCalendar';
import WithAnimation from '@/components/Common/WithAnimation';

import { ASSETS_RIBBON_BLUE, ASSETS_FLOWER_2A, ASSETS_FLOWER_2B } from '@/constants/assets';

import { BUTTON_PADDING_PROPS } from '@/constants/colors';
import { QUOTES } from '@/constants/';
import { CONTENT_STYLES, TITLE_SECTION_STYLES } from '@/constants/typography';

import txt from './locales';
import {styleCountdown} from './styles'

function CountingDown() {
  const isInvitation = useInvitation();
  const lang = useLang();

  return (
    <Box
      minHeight="80vh"
      padding="32px 0px"
      className={styleCountdown}
    >
      {/* QUOTES SECTION */}
      <Box textAlign="center">
        <WithAnimation>
          <Text {...CONTENT_STYLES} fontStyle="italic" color="alternativeColorText" fontSize="20px" px="32px">
            {QUOTES}
          </Text>
        </WithAnimation>
      </Box>
      {/* COUNTING SECTION */}
      <Box marginTop="24px" position="relative">
        <Box pos="relative" zIndex="3">
          <WithAnimation>
            <Box 
              {...TITLE_SECTION_STYLES} 
              textTransform="lowercase"
              fontSize="75px"
              marginBottom="-60px"
              >
              <Box marginBottom="-60px">counting</Box>
              <Box lineHeight="1.5em">the days</Box>
            </Box>
          </WithAnimation>
        </Box>
        <Box pos="relative" zIndex="2" marginTop="-20px" >
          <WithAnimation>
            <Box bgImage={ASSETS_RIBBON_BLUE} bgSize="contain" bgPos="center" height="400px" position="relative" bgRepeat="no-repeat">
              {/* Countdown Section */}
              <Box width="100%" position="absolute" top="60px" left="50%" transform="translateX(-50%)">
                <CountDown lang={lang} />
              </Box>

              <Box width="450px" mx="auto" height="200px" pos="relative" paddingTop="200px">
                <Image src={ASSETS_FLOWER_2A} className="flower-left" />
                <Image src={ASSETS_FLOWER_2B} className="flower-right"/>
              </Box>
            </Box>
          </WithAnimation>
        </Box>

        {/* Reminder Section */}
        {isInvitation && (
          <Box marginTop="16px">
            <WithAnimation>
              <Center>
                <AddToCalendar
                  text={txt.reminder[lang]}
                  {...BUTTON_PADDING_PROPS}
                  fontWeight="normal"
                />
              </Center>
            </WithAnimation>
        </Box>)}

      </Box>
    </Box>
  );
}

CountingDown.propTypes = {
  lang: string.isRequired,
};

export default CountingDown;
