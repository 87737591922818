import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import WithAnimation from '@/components/Common/WithAnimation';
import DetailCouple from './DetailCard';

import {
  GIRL_NAME,
  BOY_NAME,
  IG_GIRL,
  IG_BOY,
  GIRL_PARENT_NAME,
  BOY_PARENT_NAME,
  IS_BOY_FIRST,
  GIRL_NAME_SHORT,
  BOY_NAME_SHORT,
} from '@/constants';
import { IMG_GIRL, IMG_MAN, ASSETS_LEAF, ASSETS_FLOWER_1 } from '@/constants/assets';
import { TITLE_SECTION_STYLES } from '@/constants/typography'

const DetailGroom = (index) => {
  return (
    <DetailCouple
      instagramId={IG_BOY}
      fullName={BOY_NAME}
      shortName={BOY_NAME_SHORT}
      parentName={BOY_PARENT_NAME}
      imgUrl={IMG_MAN}
      i={index}
    />
  );
};

const DetailBride = (index) => {
  return (
    <DetailCouple
      instagramId={IG_GIRL}
      fullName={GIRL_NAME}
      shortName={GIRL_NAME_SHORT}
      parentName={GIRL_PARENT_NAME}
      imgUrl={IMG_GIRL}
      i={index}
    />
  );
};

function SinglePhoto({ ...rest }) {
  return (
    <Box padding="48px 0 32px 0" position="relative" bgColor="transparent" {...rest}>
      <Box padding="16px" position="relative">

        {/* ASSETS LEFT */}
        <Box position="absolute" top="-70px" left="-110px" zIndex="2" className='animation__flower'>
          <WithAnimation type="zoom">
            <Image src={ASSETS_LEAF} width="250px"></Image>
          </WithAnimation>
        </Box>
        <Box position="absolute" top="-35px" left="-70px" zIndex="5">
          <WithAnimation type="zoom" delay={1000}>
            <Image src={ASSETS_FLOWER_1} width="170px"></Image>
          </WithAnimation>
        </Box>

        {/* ASSETS RIGHT */}
        <Box position="absolute" top="-70px" right="-75px" zIndex="2" className='animation__flower'>
          <WithAnimation type="zoom">
            <Image src={ASSETS_LEAF} width="210px"></Image>
          </WithAnimation>
        </Box>
        <Box position="absolute" top="-35px" right="-40px" zIndex="5">
          <WithAnimation type="zoom" delay={1000}>
            <Image src={ASSETS_FLOWER_1} width="140px"></Image>
          </WithAnimation>
        </Box>


        <WithAnimation>
            <Box {...TITLE_SECTION_STYLES} textTransform="lowercase">
              <Box>the bride &</Box>
              <Box mt="-60px">groom</Box>
            </Box>
        </WithAnimation>

        {IS_BOY_FIRST ? <DetailGroom index={0} /> : <DetailBride index={0} />}
        <Box height="24px" />
        {IS_BOY_FIRST ? <DetailBride index={1} /> : <DetailGroom index={1} />}
      </Box>
    </Box>
  );
}

export default SinglePhoto;
